/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-rest-params */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '@/components/Navigation';
import { BaseComponentProps } from '@/types';
import Footer from '@/components/Footer';
import PricingModalComponent from '@/components/PricingModal';
import Link from '@/components/Link';
import CalendarBooking from '@/views/calendar-booking';
import { ArrowDown, ArrowUp } from '@/components/icons/Arrows';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type LayoutProps = {
  title: string;
  description: string;
  active?: string;
};

function NavigationItems(props: any) {
  const { setToggleMobileMenu, toggleMobileMenu, active = undefined } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isLandingPage =
    typeof window !== `undefined` && window.location.pathname === `/`;

  const scrollToCareTeam = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // If on the landing page, scroll to the care-team section
    if (isLandingPage) {
      event.preventDefault(); // Prevent the defaul navigation behaviour
      const careTeam = document.getElementById(`care-team`);
      if (careTeam) {
        window.scrollTo({
          top: careTeam.offsetTop,
          behavior: `smooth`, // Smooth scrolling effect
        });
      }
    }
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  // async function handleBlogClicked(evt: any) {
  //   evt?.preventDefault();
  //   await Mixpanel.track(`View Blog`);
  //   if (typeof window !== `undefined`) {
  //     window.open(`https://medium.com/@VitractOfficial`, `_blank`);
  //   }
  // }

  return (
    <div className="h-[83px] sm:max-w-[82%] mx-auto md:mx-auto overflow-hidden">
      <ToastContainer />
      <div className="flex items-center justify-center w-full h-full md:justify-between">
        <div className="flex items-center justify-center w-full h-full md:justify-between">
          <div className="h-full flex justify-start gap-x-[32px] text-gray-dark text-[14px]">
            <ul className="items-center hidden xl:flex gap-x-8">
              <li
                className={`h-full flex items-center ${
                  active === `science` && `md:border-b-[4px] md:border-primary`
                }`}
              >
                <Link
                  href="/vitract-university"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Vitract University
                </Link>
              </li>
              {/* <button
                className="text-[16px] text-navy-blue font-medium mr-[30px] md:mr-auto hover:text-[#FF713F]"
                onClick={handleBlogClicked}
                type="button"
              >
                Blog
              </button> */}
              <li
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={`h-full flex items-center ${
                  active === `blog-page` &&
                  `md:border-b-[4px] md:border-primary`
                }`}
              >
                <div className="cursor-pointer text-[16px] text-navy-blue font-medium mr-[30px] md:mr-auto hover:text-[#FF713F]">
                  Research
                </div>
                {dropdownOpen && (
                  <div className="w-[140px] flex flex-col absolute top-[64px] bg-[#FFF] border border-solid border-[#E6E6E6] py-6 px-4 rounded-lg gap-y-5">
                    <Link
                      href="/blogs"
                      className="text-[16px] text-navy-blue font-medium hover:text-[#FF713F]"
                    >
                      Blog
                    </Link>
                    <Link
                      href="/science"
                      className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                    >
                      Science
                    </Link>
                  </div>
                )}
              </li>
              <li
                className={`h-full flex items-center ${
                  active === `pricing` && `lg:border-b-[4px] lg:border-primary`
                }`}
              >
                <Link
                  href="/pricing"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Pricing
                </Link>
              </li>
              <li className="flex items-center h-full">
                <Link
                  href="/"
                  onClick={scrollToCareTeam}
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Team
                </Link>
              </li>
            </ul>
          </div>

          <div className="h-full gap-x-[77.91px] md:gap-x-[0px] flex items-center justify-center xl:pl-8">
            <Link href="/">
              <img
                src="/images/logo.svg"
                alt="Vitract | Using personalized nutrition to treat depression"
                className="h-[25px] ml-[30px] md:ml-[0px] md:mr-[235px] lg:mr-[300px] xl:mr-0 xl:ml-0"
              />
            </Link>
          </div>
          <div className="flex items-center justify-center">
            <div className="h-full flex justify-end xl:ml-[150px] text-gray-dark text-[14px]">
              <ul className="items-center hidden xl:flex gap-x-8">
                <li className="flex items-center h-full">
                  <Link
                    href="https://app.vitract.com/practitioner-registration"
                    target="_blank"
                    className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                  >
                    Practitioners
                  </Link>
                </li>
                <li className="flex items-center h-full">
                  <Link
                    href="https://app.vitract.com"
                    target="_blank"
                    className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                  >
                    Login
                  </Link>
                </li>
              </ul>
              <ul className="items-center hidden ml-8 xl:flex">
                <li className="mr-4">
                  {/* <Button
                text="Shop"
                className="text-white bg-primary px-[20px] py-[10px] rounded-[4px] text-sm font-medium"
                onClick={() => handleSignUp(null, `waitlist`)}
              /> */}
                  <Link
                    href="/pricing"
                    className="text-[#FFFFFF] bg-primary px-[36.5px] py-[10px] rounded-[4px] text-sm font-medium hover:bg-navy-blue hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out"
                  >
                    Shop
                  </Link>
                </li>
                <li>
                  <CalendarBooking />
                </li>
              </ul>
            </div>
            <div className="h-full flex justify-end gap-x-[37px] text-gray-dark text-[14px]">
              <ul className="items-center justify-between hidden md:flex xl:hidden gap-x-8">
                <li>
                  {/* <Button
                text="Shop"
                className="text-white bg-primary px-[20px] py-[10px] rounded-[4px] text-sm font-medium"
                onClick={() => handleSignUp(null, `waitlist`)}
              /> */}
                  <Link
                    href="/pricing"
                    className="text-[#FFFFFF] bg-primary px-[36.5px] py-[10px] rounded-[4px] text-sm font-medium hover:bg-navy-blue hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out"
                  >
                    Shop
                  </Link>
                </li>
                <li>
                  <CalendarBooking />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="lg:w-fit mr-[30px] md:ml-[30px] lg:mr-[0px] rounded-md xl:hidden flex justify-end"
          onClick={setToggleMobileMenu}
        >
          {!toggleMobileMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 stroke-current"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={1.5}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

export default function Layout(props: BaseComponentProps & LayoutProps) {
  const { children, description, title, active } = props;
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [togglePricingModal, setTogglePricingModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isLandingPage =
    typeof window !== `undefined` && window.location.pathname === `/`;

  const scrollToCareTeam = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // If on the landing page, scroll to the care-team section
    if (isLandingPage) {
      event.preventDefault(); // Prevent the defaul navigation behaviour
      const careTeam = document.getElementById(`care-team`);
      if (careTeam) {
        window.scrollTo({
          top: careTeam.offsetTop,
          behavior: `smooth`, // Smooth scrolling effect
        });
      }
    }
  };

  function handleClick() {
    setDropdownOpen(!dropdownOpen);
  }

  // async function handleBlogClicked(evt: any) {
  //   evt?.preventDefault();
  //   await Mixpanel.track(`View Blog`);
  //   if (typeof window !== `undefined`) {
  //     window.open(`https://medium.com/@VitractOfficial`, `_blank`);
  //   }
  // }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://vitract.com" />
        <script defer id="mcjs">
          {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/b3a74adf59f0c96f88daec5c3/a2d99679e4c859ee5a0b18939.js");`}
        </script>
      </Helmet>

      <main id="main-layout" className="overflow-hidden">
        <PricingModalComponent
          open={togglePricingModal}
          setOpen={setTogglePricingModal}
        />
        <Navigation className="bg-white nav fixed w-full z-[9999] top-0">
          {/* <div
            className="flex items-center justify-center px-4 py-2 promotion-cta md:px-0"
            data-aos="slide-down"
          >
            <p className="text-center text-[#484352] text-xs sm:text-base font-bold">
              Get up to 20% OFF on our{` `}
              <Link
                href="/pricing"
                className="text-center text-[#484352] text-xs sm:text-base font-bold underline"
              >
                Black Friday sale
              </Link>
              {` `}
              by using the code BLACKFRIDAY
            </p>

            <p className="text-center text-[#484352] text-xs sm:text-base">
              We accept Aetna insurance if you are a resident of New York, USA.
            </p>
          </div> */}
          <NavigationItems
            toggleMobileMenu={toggleMobileMenu}
            setToggleMobileMenu={() => setToggleMobileMenu(!toggleMobileMenu)}
            active={active}
            handleSignUp={() => setTogglePricingModal(true)}
          />

          <div
            className={`px-[30px] border-y-2 pt-[10px] pb-[20px] border-gray-50 ${
              toggleMobileMenu
                ? `block transition-all ease-in-out delay-150 duration-300`
                : `hidden`
            }`}
          >
            <ul className={toggleMobileMenu && `grid gap-4 items-center`}>
              <li
                className={`h-full flex items-center xl:hidden ${
                  active === `science` && `md:border-b-[4px] md:border-primary`
                }`}
              >
                <Link
                  href="/vitract-university"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Vitract University
                </Link>
              </li>
              <li
                onClick={handleClick}
                className={`h-full flex flex-col xl:hidden ${
                  active === `blog-page` &&
                  `md:border-b-[4px] md:border-primary`
                }`}
              >
                {/* <button
                  className="text-[16px] text-navy-blue font-medium mr-[30px] md:mr-auto hover:text-[#FF713F]"
                  onClick={handleBlogClicked}
                  type="button"
                >
                  Blog
                </button> */}
                <div className="flex text-[16px] text-navy-blue font-medium hover:text-[#FF713F]">
                  Research{` `}
                  <span className="py-[2px] pl-2">
                    {dropdownOpen ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </div>
                {dropdownOpen && (
                  <div className="flex flex-col gap-y-4 pt-4">
                    <Link
                      href="/blogs"
                      className="text-[16px] text-navy-blue font-medium hover:text-[#FF713F]"
                    >
                      Blog
                    </Link>
                    <Link
                      href="/science"
                      className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                    >
                      Science
                    </Link>
                  </div>
                )}
              </li>
              <li
                className={`h-full flex items-center xl:hidden ${
                  active === `pricing` && `md:border-b-[4px] md:border-primary`
                }`}
              >
                <Link
                  href="/pricing"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Pricing
                </Link>
              </li>
              <li className="flex items-center h-full">
                <Link
                  href="/"
                  onClick={scrollToCareTeam}
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Team
                </Link>
              </li>
              <li className="flex items-center h-full xl:hidden">
                <Link
                  href="https://app.vitract.com/practitioner-registration"
                  target="_blank"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Practitioners
                </Link>
              </li>
              <li className="flex items-center h-full xl:hidden">
                <Link
                  href="https://app.vitract.com"
                  target="_blank"
                  className="text-navy-blue font-medium text-[16px] hover:text-[#FF713F]"
                >
                  Login
                </Link>
              </li>
              <li className="pt-[10px] md:hidden">
                {/* <Button
                  text="Pre-Order"
                  className="text-white bg-primary px-[20px] py-[10px] rounded-[4px] text-sm font-medium"
                  onClick={() => setTogglePricingModal(true)}
                /> */}

                <Link
                  href="/pricing"
                  className="text-[#FFFFFF] bg-primary px-[36.5px] py-[10px] rounded-[4px] text-sm font-medium hover:bg-navy-blue hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out"
                >
                  Shop
                </Link>
              </li>
              <li className="pt-[10px] md:hidden">
                <CalendarBooking />
              </li>
            </ul>
          </div>
        </Navigation>
        <main className="mt-[80px]">{children}</main>
      </main>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  active: undefined,
};
